<template>
  <component :is="isPage?'div':'BSidebar'" id="user-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="userData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('user.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('user._')}}</span>
              <span class="m-0 h5">{{userData.orderNo}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'user-view', params:{userId: userData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25"/>
          <q-tab name="permission" icon="fas fa-lock-alt" :label="$t('ability._') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
        </q-tabs>
      </div>
    </template>

    <q-tabs v-if="isPage" v-model="tab" class="bg-light-secondary" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25"/>
      <q-tab name="permission" icon="fas fa-lock-alt" :label="$t('ability._') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
    </q-tabs>    
    <q-tab-panels v-model="tab" value="order" animated transition-prev="jump-left" transition-next="jump-right" class="h-100 bg-white">
      <q-tab-panel name="details" class="p-0">
        <div v-if="userData" class="pb-2">
          <Carousel :files="userData.files" />
          <div class="shadow-top-sm px-2 pt-2 flex gap-2">
            <!-- 联系人 -->
            <div class="flex-size-4 flex-grow-1 d-flex gap-2">
              <fa-icon :icon="['fad','user']" fixed-width class="text-primary text-size-150"/>
              <div>
                <div class="text-primary d-block">{{$t('form.name')|capitalize}}</div>
                <span class="text-wrap">{{userData.name}}</span>
              </div>
            </div>
            <!-- email -->
            <div class="flex-size-4 flex-grow-1 d-flex gap-2">
              <fa-icon :icon="['fad','at']" fixed-width class="text-primary text-size-150"/>
              <div>
                <div class="text-primary d-block">{{$t('form.email')|capitalize}}</div>
                <span class="text-wrap">{{userData.email}}</span>
              </div>
            </div>
            <!-- 电话 -->
            <div class="flex-size-4 flex-grow-1 d-flex gap-2">
              <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-primary text-size-150"/>
              <div>
                <div class="text-primary d-block">{{$t('form.phone')|capitalize}}</div>
                <span class="text-wrap">{{userData.country}} {{userData.phone}}</span>
              </div>
            </div>
             <!-- Tags -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','tags']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
                <span class="d-flex flex-wrap gap-1">
                  <q-chip v-for="(tag, index) in userData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
                  <q-btn flat dense @click.stop="updateTags({data:userData.tags, label:$t('form.tags'), icon: ['fad','tags']})"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
                </span>
              </div>
            </div>
          </div>
          <FieldDialog ref="fieldDialogRef"/>
        </div>
      </q-tab-panel>

      <q-tab-panel name="permission" class="p-0">
        <div v-if="userData" class="pb-2">
          <b-table small hover striped responsive class="mb-0 text-center border" responsive :items="userData.abilities" 
            :fields="[{key:'subject', label:'', class:'text-left'}].concat($global.ability_actions.map(f=>({key:f, label:$t(`ability.action.${f}`), class:'w-16'})))">
            <template #cell(subject)="{item, index}"><span class="text-bold" >{{item.s}}</span></template>
            <template #cell(create)="{item}"><q-checkbox disable v-model="item.c"/></template>
            <template #cell(read)="{item}"><q-checkbox disable v-model="item.r"/></template>
            <template #cell(update)="{item}"><q-checkbox disable v-model="item.u"/></template>
            <template #cell(delete)="{item}"><q-checkbox disable v-model="item.d"/></template>
            <template #cell(approve)="{item}"><q-checkbox disable v-model="item.a"/></template>
          </b-table>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </component>
</template>
          

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { capitalize, i18n } from '@core/utils/filter'
import { BSidebar, BTable } from 'bootstrap-vue'
import { Dialog } from '@core/utils/other'
import useUser from './useUser'
import Carousel from '@core/components/Carousel'
import FieldDialog from '@core/components/FieldDialog'

export default {
  name: 'user-view',
  components: {
    BTable,
    BSidebar,
    Carousel,
    FieldDialog
  },
  props:{
    isPage: Boolean
  },
  filters: {
    capitalize
  },
  setup(props) {
    const { route, router } = useRouter()
    const {
      loading,
      userData,
      fetchUser,
    } = useUser()
    // Page view
    if (props.isPage) {
      if (route.value.params.userId) {
        loading.value = true
        fetchUser(route.value.params.userId, ()=>loading.value = false)
      } else {
        router.go(-1)
      }
    }
    
    // Sidebar view
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchUser(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // Tabs
    const tab = ref('details')
    const sidebarWidth = ref('800px') 
    watch(tab, val=>{
      if (val==='details') sidebarWidth.value = "800px"
      if (val==='track') sidebarWidth.value = '800px'
      if (val==='journal') sidebarWidth.value = '800px'
      if (val==='reward') sidebarWidth.value = '1200px'
    })

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:userData.value.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('user/updateTags', {userId: userData.value._id, tags:newTags}).then(()=>fetchUser(userData.value._id, ()=>{
        isUpdated = true
        loading.value = false
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    return {
      loading,
      userData,

      // Sidebar
      isSidebarActive,
      sidebarWidth,
      open,
      close,

      // Tab
      tab,

      // Tags
      fieldDialogRef,
      updateTags,
    }
  }
}
</script>
<style lang="scss">
#user-view {
  .w-16 {width: 16%;}
  th > div{
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
  }
  tr {
    td:first-child {
      border-right: 1px solid black;
    }
  }
}
</style>
<template>
  <component :is="isDialog?'q-dialog':'div'" id="user-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" slim :columns="columns" :fetchAsync="fetchUsers" dataName="users" :selectable="selectable" selectMulti search="name,uid,role,phone,email,tags">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','User')" push no-caps color="primary" @click="isDialog?'':userEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>

        <!-- Name -->
        <template #cell(name)="{item}">
          <component :is="isDialog?'div':'q-btn'" flat padding="0.25rem 0.50rem" @click.stop="userViewRef.open(item._id)" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files" defaultIcon="user"/>
                <div class="text-left">
                  <div>{{ item.name }}</div>
                  <q-chip v-if="isMe(item._id)" size="sm" text-color="white" color="primary" class="mt-0">{{$t('user.me')}}</q-chip>
                </div>
              </div>
            </div>
            <fa-icon v-if="!isDialog" :icon="['far','chevron-right']" class="text-primary ml-1 mr-50"/>
          </component>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{item}">
          <div class="text-nowrap d-flex gap-1 align-items-center justify-content-center">
            <q-btn flat padding="1rem" rounded v-if="$can('update','User')" @click.stop="$router.push({ name: 'user-edit', params: { userId: item._id }})"><feather-icon icon="EditIcon" class="text-primary cursor-pointer"/></q-btn>
            <q-btn flat padding="1rem" rounded v-if="$can('create','User')" @click.stop="$router.push({ name: 'user-add', params: { userId: item._id }})"><feather-icon icon="CopyIcon" class="cursor-pointer"/></q-btn>
            <q-btn flat padding="1rem" rounded v-if="isMe(item._id) && $can('delete', 'User')" @click="deleteUser(item._id)"><feather-icon icon="TrashIcon" class="text-danger"/></q-btn>
          </div>
        </template>
      </ListTable>
      <UserView ref="userViewRef"/>
      <UserEdit ref="userEditRef"/>
    </div>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import { price, date, capitalize, i18n, isMe } from '@core/utils/filter'
import useUser from './useUser'
import store from '@/store'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'
import UserView from './UserView'
import UserEdit from './UserEdit'

export default {
  name: 'user-list',
  components: {
    Avatar, ListTable,
    UserView, UserEdit
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    price, date, capitalize
  },
  setup() {
    // Refs
    const userViewRef = ref(null)
    const userEditRef = ref(null)
    const { deleteUser } = useUser()
    
    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'name', label:i18n('form.name'), sortable: true},
      { key: 'uid', label:i18n('user.employee_id'), sortable: true},
      { key: 'role', label:i18n('form.role'), sortable: true},
      { key: 'phone', label:i18n('form.phone'), sortable: true},
      { key: 'email', label:i18n('form.email'), sortable: true},
      { key: 'tags', label:i18n('form.tags'), sortable: true},
      { key: 'actions' },
    ])
    
    // Action
    const fetchUsers = params => store.dispatch('user/fetchUsers', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      isMe,
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchUsers,
      deleteUser,

      userViewRef,
      userEditRef
    }
  }
}
</script>
<style lang="scss">
#user-list {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
}
</style>

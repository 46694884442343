<template>
  <component :is="isPage?'div':'q-dialog'" id="user-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="userData._id?'pen':'plus'" :class="userData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`user.${userData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: userData._id?'user-edit':'user-add', params:{userId: userData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>

      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" class="h-100" delay='600' 
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <div class="p-2 flex gap-2 flex-column flex-sm-row">
              <!-- 联系人 -->
              <q-input type="text" outlined hide-bottom-space v-model="userData.name"
                :label="$t('form.name')|capitalize" class="flex-even m-0 flex-size-4" :rules="[ val=>Boolean(val)||$t('rule.required'), val=> val.length<=50 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','user']" fixed-width class="text-danger mr-25"/></template>
              </q-input>
              <!--- 密码 -->
              <q-input v-model="userData.password" outlined :type="isPwd ?'password':'text'" :label="$t('user.password')|capitalize" class="flex-even m-0 flex-size-4">
                <template #prepend><fa-icon :icon="['fad','key']" fixed-width class="text-primary mr-25"/></template>
                <template #append><q-icon :name="isPwd?'visibility_off':'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" /></template>
              </q-input>
              <!-- email -->
              <q-input type="email" outlined hide-bottom-space v-model="userData.email" :label="$t('form.email')|capitalize" class="flex-even m-0 flex-size-4" lazy-rules
                :rules="[ val=>Boolean(val)||$t('rule.required'), val=> !val || /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || $t('rule.email'), val=>!val || val.length<=30 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','at']" fixed-width class="text-danger mr-25"/></template>
              </q-input>
              <!-- 电话 -->
              <q-input type="tel" outlined hide-bottom-space clearable v-model="userData.phone" :mask="userData.mask" unmasked-value 
                :label="$t('form.phone')|capitalize" class="flex-even phone m-0 flex-size-4" :rules="[ val=>Boolean(val)||$t('rule.required'), val=>val.length<=50 || $t('rule.too_long')]">
                <template #prepend>
                  <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-primary mr-75"/>
                  <q-select ref="codeRef" outlined square prefix="+" :options="$global.countries" :label="$t('form.country')" v-model="userData.country" 
                    class="bg-light-primary mb-0" popup-content-class="mt-1" style="min-width: 120px">
                    <template #option="scope">
                      <q-item v-bind="scope.itemProps" class="d-flex gap-1 align-items-center" @click="userData.country = scope.opt.value;userData.mask = scope.opt.mask;$refs.codeRef.hidePopup()">
                        <q-chip color="primary" text-color="white">{{scope.opt.value}}</q-chip>{{$t('country.'+scope.opt.label)}}
                      </q-item>
                    </template>
                  </q-select>
                </template>
              </q-input>
              <!-- tags -->
              <q-select v-model="userData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input 
                popup-content-class="mt-1" :placeholder="$t('message.enter_add_new')" class="flex-even mb-0 flex-size-4">
                <template #selected-item="scope">
                  <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                </template>
                <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
              </q-select>
            </div>

            <!-- Permission -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize"><fa-icon :icon="['fad','lock-alt']" fixed-width />{{$t('ability._')}}</span>
            </h5>
            <div class="p-2">
              <b-table small hover striped responsive class="mb-0 shadow rounded-lg text-center" responsive :items="userData.abilities" 
                :fields="[{key:'subject', label:'', class:'text-left'}].concat($global.ability_actions.map(f=>({key:f, label:$t(`ability.action.${f}`), class:'w-16'})))">
                <template #cell(subject)="{item, index}">
                  <div class="d-flex justify-content-between gap-1 align-items-center">
                    <q-btn flat class="mb-0" >{{item.s}}</q-btn>
                    <div class="text-nowrap">
                      <q-btn flat rounded padding="1rem" @click="toggleAbilityRow(index)"><fa-icon :icon="['far','check-square']"/></q-btn>
                      <q-btn flat rounded padding="1rem" @click="toggleAbilityRow(index, false)"><fa-icon :icon="['far','square']"/></q-btn>
                    </div>
                  </div>
                </template>
                <template #cell(create)="{item}"><q-checkbox v-model="item.c"/></template>
                <template #cell(read)="{item}"><q-checkbox v-model="item.r"/></template>
                <template #cell(update)="{item}"><q-checkbox v-model="item.u"/></template>
                <template #cell(delete)="{item}"><q-checkbox v-model="item.d"/></template>
                <template #cell(approve)="{item}"><q-checkbox v-model="item.a"/></template>
              </b-table>
            </div>
            
            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize"><fa-icon :icon="['fad','image']" fixed-width />{{$t('form.image')}}</span>
            </h5>
            <FileUpload size="160px" model="user" :modelId="userData._id" :files.sync="userData.files" limit="5" class="p-2"/>
          </div>
        </component>

        <div class="d-flex justify-content-between p-2 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="danger" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize, i18n, isMe } from '@core/utils/filter'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { Toast, Dialog } from '@core/utils/other'
import { BTable } from 'bootstrap-vue'
import useUser from './useUser'
import FileUpload from '@core/components/file-upload/FileUpload'

export default {
  name: 'user-edit',
  components: {
    BTable,
    FileUpload
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    },
  },
  filters:{
    capitalize
  },
  setup(props) {
    const { route, router } = useRouter()
    const {
      loading,
      userData,
      fetchUser,
      resetUser,
    } = useUser()

    // 密码
    const isPwd = ref(true)

    // Actions
    let submit = ()=>{
      loading.value = true
      store.dispatch(userData.value._id?'user/updateUser':'user/addUser', userData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetUser()
      if (route.value.params.userId){
        loading.value = true
        fetchUser(route.value.params.userId, ()=>loading.value = false)
      }
    }
    
    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async userId => {
      resetUser()
      isDialogOpen.value = true
      if(userId) {
        loading.value = true
        await fetchUser(userId, ()=> loading.value = false)
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(userId?'user/updateUser':'user/addUser', userData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

    const toggleAbilityRow = (i, value=true) =>{
      for (let key in userData.value.abilities[i]){
        if (key!=='s') userData.value.abilities[i][key] = value
      }
    }

    return {
      isMe, isPwd,
      loading, capitalize,
      userData,

      // Dailog
      isDialogOpen,
      open,
      cancel,

      clickSubmit,
      toggleAbilityRow
    }
  },
}
</script>
<style lang="scss">
#user-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .w-16 {width: 16%;}
  th > div{
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
  }
  tr {
    td:first-child {
      border-right: 1px solid black;
    }
  }
}
</style>
import { ref } from '@vue/composition-api'
import store from '@/store'
import $global from '@/globalVariables'
import userStoreModule from './userStoreModule'
if (!store.hasModule('user')) store.registerModule('user', userStoreModule)
import { Dialog } from '@core/utils/other'
import { i18n } from '@core/utils/filter'

export default function useUserList() {
  const loading = ref(false)
  const formatAbilities = abilities=> $global.ability_subjects.map(s=>{
    const emptyAbility = {s}
    let currentAbility = {}
    if (abilities) currentAbility = abilities.find(a=>a.s === s) || {}
    $global.ability_actions.forEach(key=>emptyAbility[key.charAt(0)] = false)
    return Object.assign(emptyAbility, currentAbility)
  })

  const emptyUser = ()=>({
    country: $global.default_country_code,
    mask: $global.default_phone_mask,
    tags:[],
    ability: [],// For add user
    abilities: formatAbilities()
  })
  const userData = ref(emptyUser())
  const resetUser = () => userData.value = emptyUser()

  // Fetch a user
  const fetchUser = (id, callback) => {
    if (!id) return
    return store.dispatch('user/fetchUser', id).then(response => {
      userData.value = {...response.user, abilities: formatAbilities(response.user.abilities)}
      if (callback) callback(response)
    }).catch(() => userData.value = null )
  }

  const deleteUser = async id =>{
    const v = await Dialog(true, 'danger').fire({
      icon: 'warning',
      title: i18n('message.if_confirm_delete'),
      confirmButtonText: i18n('action.delete')
    })
    if (v.value) return store.dispatch('user/deleteUser', id)
  }

  return {
    loading,
    userData,
    resetUser,
    fetchUser,
    deleteUser,
  }
}
